import React from 'react';
import { SegmentedControl as PrivateControl } from 'pws-design-system/design-system';

const SegmentedControl = (props: any) => (
    <PrivateControl
        bg="bg.base.primary"
        p="2px"
        size="xs"
        spacing={0}
        variant="link"
        variantColor="default"
        selectedVariant="outline-fill"
        selectedVariantColor="default"
        rounded="full"
        {...props}
    />
);

export default SegmentedControl;
