import React from 'react';
import { Box, Text, Stack } from 'pws-design-system/design-system';
import Card, { CardHeading } from '../ui/Card';
import ToggleRow from '../ui/ToggleRow';
import User from '../../models/users/User';

const attributes = [{
    key: 'temp',
    title: 'Temp',
}, {
    key: 'speed',
    title: 'Speed/Wind',
}, {
    key: 'distance',
    title: 'Distance',
}, {
    key: 'height',
    title: 'Height/Elevation',
}, {
    key: 'pressure',
    title: 'Pressure',
}, {
    key: 'rain',
    title: 'Rain',
}, {
    key: 'snow',
    title: 'Snow',
}];

interface UnitSettingsViewProps {
    user: User;
}

const UnitSettingsView = ({ user }: UnitSettingsViewProps) => {

    const handleChangeUnit = (key: string, value: any) => {
        user.setPreference(`units.${key}`, value);
    };

    return (
        <Card>
            <CardHeading>Unit Settings</CardHeading>
            <Text variant="caption1" color="text.base.secondary">
                Select the unit you want to display for each each measurement below.
            </Text>
            <Stack spacing={2} mt={4}>
                <Box height="2px" bg="bg.100" />
                {attributes.map(({ key, title }: any) => (
                    <Stack spacing={2} key={`prop-${key}`}>
                        <ToggleRow
                            label={title}
                            value={user.getPreference(`units.${key}`)}
                            options={[{
                                value: 'imperial',
                                label: 'Imperial',
                            }, {
                                value: 'metric',
                                label: 'Metric',
                            }]}
                            onChange={(value: any) => {
                                handleChangeUnit(key, value);
                            }}
                        />
                        <Box height="2px" bg="bg.100" />
                    </Stack>
                ))}
            </Stack>
        </Card>
    );
};

export default UnitSettingsView;
