import React, { useRef, useState, useEffect } from 'react';
import { Router } from '@reach/router';
import { navigate, withPrefix } from 'gatsby';
import {
    Avatar, Box, Button, Col, ExpandingMenu, Hide, Row, TextInput,
} from 'pws-design-system/design-system';
import DashboardLayout from '../layouts/DashboardLayout';
import ContentPanelLayout from '../layouts/ContentPanelLayout';
import ProfileView from '../views/users/profile/ProfileView';
import UnitSettingsView from '../views/UnitSettingsView';
import EditorToolbar from '../ui/EditorToolbar';
import Dialog from '../ui/Dialog';
import Toast, { useToast } from '../ui/Toast';
import useDataBinding from '../hooks/useDataBinding';
import useAuth from '../../context/auth';
import { useMobileQuery } from '../hooks/useMediaQuery';

import useApi, { Api } from '../hooks/api/useApi';
import User from '../../models/users/User';
import ProtectedResource from '../ProtectedResource';

enum ViewType {
    profile = 'profile',
    settings = 'settings'
}

interface AccountPageProps {}

const AccountPageContent = () => {
    const session = useAuth();
    const [password, setPassword] = useState<string>(null);
    const $confirmDelete = useDataBinding(false);
    const $requestPassword = useDataBinding(false);
    const toast = useToast();
    const formRef = useRef<any>();
    const isMobile = useMobileQuery();

    const { request: updateAccount, result, isLoading: isSaving } = useApi<User>((api: Api, data: any) => (
        api.routes.account.updateProfile(data)
    ));
    const { request: deleteAccount, result: deleteResult, isLoading: isDeleting } = useApi<User>((api: Api, data: any) => (
        api.routes.account.deleteAccount(data)
    ));

    const handleTabSelect = (type: ViewType) => {
        navigate(`/account/${type === ViewType.profile ? '' : type}`);
    };

    const handleSave = () => {
        if (formRef.current) {
            formRef.current.submit();
        }
    };

    const handleSubmit = (data: any = {}) => {
        data.preferences = session.user.preferences; // eslint-disable-line no-param-reassign
        updateAccount(data);
    };

    const handleDeleteRequest = () => {
        $confirmDelete.setValue(true);
    };

    const handlePasswordRequest = () => {
        $confirmDelete.setValue(false);
        $requestPassword.setValue(true);
    };

    const handleDelete = () => {
        deleteAccount({ password });
    };

    const showToast = (success: boolean, title: string, message: string) => {
        toast({
            duration: 5000,
            isClosable: true,
            render: () => (
                <Toast variant={success ? 'success' : 'error'} title={title} message={message} icon="check-circle" />
            ),
        });
    };

    useEffect(() => {
        if (result.response) {
            if (result.success) {
                showToast(true, 'Settings updated', 'Your account settings were updated successfully.');
            } else {
                showToast(false, 'Settings failed to update', result.error ? result.error.message : 'Your account settings could not be saved at this time. Please try again.');
            }
        }
    }, [result]);

    return (
        <>
            <ContentPanelLayout
                title={session.user ? session.user.name : '--'}
                category="User"
                leftElement={(
                    <Avatar
                        size="lg"
                        mt={-3}
                        name={session.user && session.user.name}
                        src=""
                    />
                )}
                toolbarElement={(
                    <EditorToolbar
                        modelName="Settings"
                        enableDelete={false}
                        isEditing={false}
                        isSaving={isSaving}
                        isInlineMobile={false}
                        extraMobileElements={(
                            <Button
                                width="full"
                                variantColor="dark"
                                aria-label="Delete Account"
                                onClick={handleDeleteRequest}
                            >
                                Delete Account
                            </Button>
                        )}
                        onSave={handleSave}
                    />
                )}
                toolbarPlacement={isMobile ? 'bottom' : 'top'}
                showKeyline
            >
                <Hide tablet desktop>
                    <ExpandingMenu
                        height={46}
                        variant="light"
                        items={[
                            {
                                value: ViewType.profile,
                                label: 'Profile',
                            },
                            {
                                value: ViewType.settings,
                                label: 'Unit Preferences',
                            },
                        ]}
                        onSelect={(item: any) => {
                            handleTabSelect(item.value);
                        }}
                    />
                    <Box mt={[3, null, null, 4]}>
                        <Router basepath={withPrefix('/account')} primary={false}>
                            <ProfileView
                                path="/"
                                 isMe
                                user={session.user}
                                formRef={formRef}
                                onSubmit={handleSubmit}
                            />
                            <UnitSettingsView path="settings" user={session.user} />
                        </Router>
                    </Box>
                </Hide>
                <Hide mobile>
                    <Row>
                        <Col lg={4}>
                            <ProfileView
                                isMe
                                user={session.user}
                                formRef={formRef}
                                onSubmit={handleSubmit}
                            />
                            <Hide mobile>
                                <Button size="sm" variantColor="dark" onClick={handleDeleteRequest}>
                                    Delete Account
                                </Button>
                            </Hide>
                        </Col>
                        <Col lg={4}>
                            <UnitSettingsView user={session.user} />
                        </Col>
                    </Row>
                </Hide>
            </ContentPanelLayout>
            <Dialog
                binding={$confirmDelete}
                title="Delete Account?"
                message="Are you sure you want to delete your account? This cannot be undone."
                buttons={[
                    <Button
                        size="sm"
                        key="btn-cancel"
                        variant="outline"
                        variantColor="dark"
                        onClick={() => $confirmDelete.setValue(false)}
                    >
                        Cancel
                    </Button>,
                    <Button
                        size="sm"
                        key="btn-confirm"
                        variant="solid"
                        variantColor="destructive"
                        onClick={() => handlePasswordRequest()}
                    >
                        Delete Account
                    </Button>,
                ]}
            />
            <Dialog
                binding={$requestPassword}
                title="Delete Account"
                message="Your account password is required when deleting your account. Enter your password below."
                buttons={[
                    <Button
                        size="sm"
                        key="btn-cancel"
                        variant="outline"
                        variantColor="dark"
                        onClick={() => $requestPassword.setValue(false)}
                    >
                        Cancel
                    </Button>,
                    <Button
                        size="sm"
                        key="btn-confirm"
                        variant="solid"
                        variantColor="destructive"
                        onClick={() => handleDelete()}
                    >
                        Delete Account
                    </Button>,
                ]}
            >
                <TextInput
                    mt={3}
                    type="password"
                    variant="flushed"
                    placeholder="Password..."
                    onChange={(e: any) => setPassword(e.target.value)}
                />
            </Dialog>
        </>
    );
};

const AccountPage = ({ ...rest }: AccountPageProps & any) => (
    <ProtectedResource>
        <DashboardLayout title="Account" {...rest}>
            <AccountPageContent />
        </DashboardLayout>
    </ProtectedResource>
);

export default AccountPage;
